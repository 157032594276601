export function checkUserReadPermission(user, permission_type) {
  let permissions = user.permissions ?? [];
  let permission = permissions.find(item => item['permission'].type === permission_type);
  return !(!permission) && ((permission.value === 'Read') || (permission.value === 'Write'));
}

export function checkUserWritePermission(user, permission_type) {
  let permissions = user.permissions ?? [];
  let permission = permissions.find(item => item['permission'].type === permission_type);
  return !(!permission) && (permission.value === 'Write');
}

export function numberWithCommas(x) {
  if (x === undefined || x === null) {
    return "";
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export function formatMoney(number) {
  if (isNaN(Number(number))) {
    return '';
  }
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  return Number(number).toLocaleString('en', options);
}

export function unFormatMoney(string) {
  if (string === null || string === undefined) {
    return 0;
  } else {
    return Number(`${string}`.replace(/,/g, ''));
  }
}

export function ExcelDateToJSDateString(serial) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"];

  let utc_days = Math.floor(serial - 25569);
  let utc_value = utc_days * 86400;
  let date_info = new Date(utc_value * 1000);

  let year = date_info.getFullYear();
  year = year - Math.floor(year / 100) * 100;
  return `${date_info.getDate()}-${monthNames[date_info.getMonth()]}-${year}`;
}

export function ExcelDateToJSDateString_2(serial) {
  let utc_days = Math.floor(serial - 25569);
  let utc_value = utc_days * 86400;
  let date_info = new Date(utc_value * 1000);

  let year = date_info.getFullYear();
  let month = `${date_info.getMonth() + 1}`;
  let day = `${date_info.getDate()}`;
  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  return [day, month, year].join('-');
}

export function ExcelDateToJSDateString_3(serial) {
  let utc_days = Math.floor(serial - 25569);
  let utc_value = utc_days * 86400;
  let date_info = new Date(utc_value * 1000);

  let year = date_info.getFullYear();
  let month = `${date_info.getMonth() + 1}`;
  let day = `${date_info.getDate()}`;
  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  return [year, month, day].join('-');
}

export function getDateString(date) {
  if (date === null || date === undefined || date === "") {
    return "";
  } else {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
}

export function getDateString_2(date) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"];
  if (date === null || date === undefined || date === "") {
    return "";
  } else {
    let d = new Date(date),
      month = d.getMonth(),
      day = d.getDate(),
      year = d.getFullYear();

    year = year - Math.floor(year / 100) * 100;
    return `${day}-${monthNames[month]}-${year}`;
  }
}

export function getDays(start, last) {
  // calculation for converting a day into milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // calculation for the time difference between start and last
  const diffTime = last.getTime() - start.getTime();

  // calculation for the days between start and last
  const diffDays = Math.floor(diffTime / oneDay);
  // return number of days
  return diffDays;
}

export function groupBy(list, property) {
  return list.reduce(function (memo, x) {
    if (!memo[x[property]]) { memo[x[property]] = []; }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

export function getYearList() {

  let arr = [
    { value: '2024', text: '2024' },
    { value: '2023', text: '2023' },
    { value: '2022', text: '2022' },
    { value: '2021', text: '2021' },
    { value: '2020', text: '2020' },
    { value: '2019', text: '2019' },
    { value: '2018', text: '2018' },
    { value: '2017', text: '2017' },
    { value: '2016', text: '2016' },
    { value: '2015', text: '2015' },
    { value: '2014', text: '2014' },
    { value: '2013', text: '2013' },
    { value: '2012', text: '2012' },
    { value: '2011', text: '2011' },
    { value: '2010', text: '2010' },
    { value: '2009', text: '2009' },
    { value: '2008', text: '2008' },
    { value: '2007', text: '2007' },
    { value: '2006', text: '2006' },
    { value: '2005', text: '2005' },
    { value: '2004', text: '2004' },
    { value: '2003', text: '2003' },
    { value: '2002', text: '2002' },
    { value: '2001', text: '2001' },
    { value: '2000', text: '2000' },
    { value: '1999', text: '1999' },
    { value: '1998', text: '1998' },
    { value: '1997', text: '1997' },
    { value: '1996', text: '1996' },
    { value: '1995', text: '1995' },
    { value: '1994', text: '1994' },
    { value: '1993', text: '1993' },
    { value: '1992', text: '1992' },
    { value: '1991', text: '1991' },
    { value: '1990', text: '1990' },
    { value: '1989', text: '1989' },
    { value: '1988', text: '1988' },
    { value: '1987', text: '1987' },
    { value: '1986', text: '1986' },
    { value: '1985', text: '1985' },
    { value: '1984', text: '1984' },
    { value: '1983', text: '1983' },
    { value: '1982', text: '1982' },
    { value: '1981', text: '1981' },
    { value: '1980', text: '1980' },
    { value: '1979', text: '1979' },
    { value: '1978', text: '1978' },
    { value: '1977', text: '1977' },
    { value: '1976', text: '1976' },
    { value: '1975', text: '1975' },
    { value: '1974', text: '1974' },
    { value: '1973', text: '1973' },
    { value: '1972', text: '1972' },
    { value: '1971', text: '1971' },
    { value: '1970', text: '1970' },
    { value: '1969', text: '1969' },
    { value: '1968', text: '1968' },
    { value: '1967', text: '1967' },
    { value: '1966', text: '1966' },
    { value: '1965', text: '1965' },
    { value: '1964', text: '1964' },
    { value: '1963', text: '1963' },
    { value: '1962', text: '1962' },
    { value: '1961', text: '1961' },
    { value: '1960', text: '1960' },
    { value: '1959', text: '1959' },
    { value: '1958', text: '1958' },
    { value: '1957', text: '1957' },
    { value: '1956', text: '1956' },
    { value: '1955', text: '1955' },
    { value: '1954', text: '1954' },
    { value: '1953', text: '1953' },
    { value: '1952', text: '1952' },
    { value: '1951', text: '1951' },
    { value: '1950', text: '1950' },
    { value: '1949', text: '1949' },
    { value: '1948', text: '1948' },
    { value: '1947', text: '1947' },
    { value: '1946', text: '1946' },
    { value: '1945', text: '1945' },
    { value: '1944', text: '1944' },
    { value: '1943', text: '1943' },
    { value: '1942', text: '1942' },
    { value: '1941', text: '1941' },
    { value: '1940', text: '1940' },
    { value: '1939', text: '1939' },
    { value: '1938', text: '1938' },
    { value: '1937', text: '1937' },
    { value: '1936', text: '1936' },
    { value: '1935', text: '1935' },
    { value: '1934', text: '1934' },
    { value: '1933', text: '1933' },
    { value: '1932', text: '1932' },
    { value: '1931', text: '1931' },
    { value: '1930', text: '1930' },
    { value: '1929', text: '1929' },
    { value: '1928', text: '1928' },
    { value: '1927', text: '1927' },
    { value: '1926', text: '1926' },
    { value: '1925', text: '1925' },
    { value: '1924', text: '1924' },
    { value: '1923', text: '1923' },
    { value: '1922', text: '1922' },
    { value: '1921', text: '1921' },
    { value: '1920', text: '1920' },
  ];
  return arr;
}

export function checkValidationApplication(param, errs = []) {
  let errList = [...errs]
  let result = {
    status: true,
    message: "",
  };
  if (!param.qualificationLevel) {
    result = {
      status: false,
      field: "qualificationLevel",
      message: "Please select Qualification Level",
    };
  } else if (param.qualificationLevel !== "Post Graduate" && !param.applyingMatureAge) {
    result = {
      status: false,
      field: "applyingMatureAge",
      message: "Are you applying through mature age?",
    };
  } else if (!param.qualificationId_1) {
    result = {
      status: false,
      field: "qualificationId_1",
      message: "Please select Qualification1",
    };
  } else if (!param.studyModeId_1) {
    result = {
      status: false,
      field: "studyModeId_1",
      message: "Please select Study Mode1",
    };
  } 
  // else if (!param.qualificationId_2) {
  //   result = {
  //     status: false,
  //     field: "qualificationId_2",
  //     message: "Please select Qualification2",
  //   };
  // } else if (!param.studyModeId_2) {
  //   result = {
  //     status: false,
  //     field: "studyModeId_2",
  //     message: "Please select Study Mode2",
  //   };
  // } 
  
  else if (param.applyingMatureAge === "Yes" && !param.employerName) {
    result = {
      status: false,
      field: "employerName",
      message: "Please input Name of Employer",
    };
  } else if (param.applyingMatureAge === "Yes" && !param.yourOccupation) {
    result = {
      status: false,
      field: "yourOccupation",
      message: "Please input Your Occupation",
    };
  } else if (param.applyingMatureAge === "Yes" && !param.startDate) {
    result = {
      status: false,
      field: "startDate",
      message: "Please select Start Date",
    };
  } else if (param.applyingMatureAge === "Yes" && !param.endDate) {
    result = {
      status: false,
      field: "endDate",
      message: "Please select End Date",
    };
  } else if (param.applyingMatureAge === "Yes" && !param.mainDutiesPerformed) {
    result = {
      status: false,
      field: "mainDutiesPerformed",
      message: "Please input Main Duties Performed",
    };
  } else if (param.applyingMatureAge === "Yes" && !param.telNumber) {
    result = {
      status: false,
      field: "telNumber",
      message: "Please input Tel No",
    };
  } else if (param.applyingMatureAge === "Yes" && !param.employerEmail) {
    result = {
      status: false,
      field: "employerEmail",
      message: "Please input Employer Email",
    };
  }
  else if (param.qualificationLevel !== "Post Graduate" && (param.lastSecondarySchoolId === undefined || param.lastSecondarySchoolId === "" || param.lastSecondarySchoolId === null)) {
    result = {
      status: false,
      field: "lastSecondarySchoolId",
      message: "Please select Last Secondary School",
    };
  } else if (param.qualificationLevel !== "Post Graduate" && param?.lastSecondarySchoolId == 0 && !param.schoolSpecify) {
    result = {
      status: false,
      field: "schoolSpecify",
      message: "Please select Last Secondary School Specify",
    };
  } else if (param.qualificationLevel !== "Post Graduate" && !param.highestGradePassed) {
    result = {
      status: false,
      field: "highestGradePassed",
      message: "Please input Highest Grade Passed",
    };
  } else if (param.qualificationLevel !== "Post Graduate" && !param.examinationDate) {
    result = {
      status: false,
      field: "examinationDate",
      message: "Please select Examination Date",
    };
  } else if (param.qualificationLevel !== "Post Graduate" && !param.schoolAddress) {
    result = {
      status: false,
      field: "schoolAddress",
      message: "Please input School Address",
    };
  } else if (!param.degree) {
    result = {
      status: false,
      field: "degree",
      message: "Please select high school qualification",
    };
  } else if (!param.payment) {
    result = {
      status: false,
      field: "payment",
      message: "Please select Person/Organization responsible for payments",
    };
  } else if (param.payment === "Other" && !param.specifyPerson) {
    result = {
      status: false,
      field: "specifyPerson",
      message: "Please input Specify Person or Organization",
    };
  } else if (!param.postalAddress) {
    result = {
      status: false,
      field: "postalAddress",
      message: "Please input Postal Address",
    };
  } else if (!param.email) {
    result = {
      status: false,
      field: "email",
      message: "Please input Email Address",
    };
  } else if (!param.telephone) {
    result = {
      status: false,
      field: "telephone",
      message: "Please input Telephone",
    };
  } else if (!param.internetAccess) {
    result = {
      status: false,
      field: "internetAccess",
      message: "Please select Internet Access",
    };
  } else if (!param.computerAccess) {
    result = {
      status: false,
      field: "computerAccess",
      message: "Please select Compter Access",
    };
  } else if (!param.cellphoneAccess) {
    result = {
      status: false,
      field: "cellphoneAccess",
      message: "Please select Cellphone Access",
    };
  }
  
  if (result.status) {
    if (!param.ckPaymentProof) {
      errList = [...errList, "ckPaymentProof"]
      result = {
        status: false,
        field: "ckPaymentProofFile",
        message: "Did you upload proof of payment for application fee?",
      };
    } 
    if (!param.ckLatestSchoolResult) {
      errList = [...errList, "ckLatestSchoolResult"]
      result = {
        status: false,
        field: "ckLatestSchoolResultFile",
        message: "Did you upload certified copy of latest school results?",
      };
    } 
    if (!param.slAcademicRecord || (param.slAcademicRecord === 'Yes' && !param.slAcademicRecordFileName)) {
      errList = [...errList, "slAcademicRecord"]
      result = {
        status: false,
        field: "slAcademicRecord",
        message: "Did you upload certified copies of all tertiary qualification(s) and academic record (s)?",
      };
    } 
    if (!param.ckIdDocument) {
      errList = [...errList, "ckIdDocument"]
      result = {
        status: false,
        field: "ckIdDocumentFile",
        message: "Did you upload certified copy ID document (Namibian Citizens) or Passport (Foreign Students)??",
      };
    } 
    if (!param.slLanguage || (param.slLanguage === 'Yes' && !param.slLanguageFileName)) {
      errList = [...errList, "slLanguage"]
      result = {
        status: false,
        field: "slLanguage",
        message: "Did you upload an original official translation of the foreign qualification - if in a foreign language other than English.",
      };
    } 
    if (!param.slQualification || (param.slQualification === 'Yes' && !param.slQualificationFileName)) {
      errList = [...errList, "slQualification"]
      result = {
        status: false,
        field: "slQualification",
        message: "Did you upload a certified copy of the Namibia Qualification Authority (NQA) evaluation of foreign qualifications (if qualification is not obtained in Namibia)",
      };
    } 
    if (!param.slMarriage || (param.slMarriage === 'Yes' && !param.slMarriageFileName)) {
      errList = [...errList, "slMarriage"]
      result = {
        status: false,
        field: "slMarriage",
        message: "Did you upload a certified copy of the marriage certificate or divorce order?",
      };
    } 
    if (!param.slOriginalLetter || (param.slOriginalLetter === 'Yes' && !param.slOriginalLetterFileName)) {
      errList = [...errList, "slOriginalLetter"]
      result = {
        status: false,
        field: "slOriginalLetter",
        message: "Did you upload an original letter from the employer?",
      };
    }
    
    result.errList = errList
  } else {
    result.errList = errList
  }
  
  return result;
}

